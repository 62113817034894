<template>
  <v-list class="list mt-0">
    <v-row class="title-bg">
      <v-img
        class="title my-auto"
        max-width="120"
        max-height="18"
        :src="levelImg"
      ></v-img>
    </v-row>
    <v-list-item
      class="item"
      :style="showLeftPadding ? 'padding-left:82px' : 'padding-left:6px'"
      v-for="book in books"
      :key="book.id"
      @click="itemClickHandler(book.id)"
    >
      <c-img-book-cover
        :src="book.cover"
        max-width="118"
        max-height="156"
        width="118"
        height="156"
        :title="book.name"
        class="my-auto ml-2"
      ></c-img-book-cover>
      <div class="text-content">
        <v-clamp
          class="common--text font-weight-medium f20"
          autoresize
          :max-lines="2"
          >{{ book.name }}</v-clamp
        >
        <v-clamp autoresize :max-lines="1" class="mt-4 f16 body--text">{{
          book.writer
        }}</v-clamp>
        <v-clamp autoresize :max-lines="1" class="mt-3 f16 body--text">{{
          book.desc
        }}</v-clamp>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
import CImgBookCover from "@/components/c-img-book-cover/c-img-book-cover";
import VClamp from "vue-clamp";
export default {
  props: {
    books: {
      type: Array,
      default: () => {},
    },
    currentLevel: {
      type: Number,
      default: () => {},
    },
  },
  created() {
    this.levelImg = this.getlevelImg(this.currentLevel);
  },
  data() {
    return {
      levelImg: "",
    };
  },
  methods: {
    getlevelImg(level) {
      return require("@/assets/level_gold/level_gold_" + level + ".png");
    },
    itemClickHandler(bid) {
      this.$router.push({
        name: "pcBookDetail",
        params: {
          bookId: bid.toString(),
        },
      });

      this.$track.event({
        category: "book",
        name: "visit",
        value: bid.toString(),
        params: {
          referer_action: "tag_page",
          tag: this.currentLevel.toString(),
        },
      });
    },
  },
  watch: {
    levelSelected(val) {
      this.levelImg = this.getlevelImg(val);
    },
  },
  computed: {
    levelSelected() {
      return this.currentLevel;
    },
    showLeftPadding() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  components: {
    VClamp,
    CImgBookCover,
  },
};
</script>

<style lang="scss" scoped>
.list {
  border-left: 1px solid #f0f0f0;
}

.title-bg {
  height: 60px;
}

.title {
  margin-left: 40px;
}

.item {
  width: 100%;
  height: 205px;
  padding-left: 82px;
  background: white;
  border-radius: 6px;
  &:hover {
    background: linear-gradient(
      270deg,
      rgba(251, 252, 253, 0.2) 0%,
      rgba(201, 201, 201, 0.2) 100%
    );
  }
}

.text-content {
  height: 100%;
  width: 100%;
  margin: 52px 0px 0px 30px;
}
</style>
