<template>
  <div :class="dialog ? 'blurred' : ''">
    <div class="head">
      <v-row class="ma-auto head-info" justify="space-between">
        <v-col cols="auto" class="my-auto col" v-if="showIcon">
          <v-img
            src="@/assets/img_hy_icon_text.png"
            max-height="30px"
            max-width="154px"
          ></v-img>
        </v-col>
        <v-col cols="auto" class="my-auto col search-text-bg">
          <v-text-field
            hide-details
            color="minor"
            rounded
            placeholder="搜索"
            class="search-text my-auto f16"
            @click="searchTextClickHandler"
          >
            <v-icon slot="prepend-inner" size="18px" color="info"
              >icon-search</v-icon
            >
          </v-text-field>
        </v-col>
        <v-col cols="auto" class="my-auto col pa-0" v-if="showIcon">
          <pc-frame class="user-info my-auto"> </pc-frame>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <v-row class="pa-0 content">
      <v-col cols="auto" class="pa-0 ma-0" v-if="showIcon">
        <pc-more-books-levels
          :currentLevel="currentLevel"
          @levelSelected="levelSelectedHandler"
        ></pc-more-books-levels>
      </v-col>
      <v-col class="pa-0 ma-0 item" :key="listkey">
        <pc-more-books-list
          :currentLevel="currentLevel"
          :books="books"
        ></pc-more-books-list>

        <infinite-loading
          ref="infiniteLoading"
          :distance="10"
          @infinite="infiniteHandler"
          spinner="circles"
        >
          <div slot="no-more">
            <p class="f16 minor--text mt-6">更多内容,敬请期待～</p>
          </div>
        </infinite-loading>
      </v-col>
    </v-row>
    <pc-reader-book-search :dialog.sync="dialog"></pc-reader-book-search>
  </div>
</template>

<script>
import PcFrame from "@/views/pc/index/pc-frame.vue";
import PcMoreBooksLevels from "@/views/pc/more-books/pc-more-books-levels.vue";
import PcMoreBooksList from "@/views/pc/more-books/pc-more-books-list.vue";
import PcReaderBookSearch from "@/views/pc/components/pc-reader-book-search.vue";
import { getPCBookList } from "@/api/api-pc.js";
import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
export default {
  mixins: [PcMixins],
  data() {
    return {
      searchText: "",
      page: 0,
      limit: 10,
      currentLevel: 1,
      books: [],
      needLoadMore: true,
      listkey: 0,
      dialog: false,
    };
  },
  methods: {
    getBooks() {
      getPCBookList(this.page, this.limit, this.currentLevel).then((res) => {
        this.books = res.books;
      });
    },
    levelSelectedHandler(level) {
      this.currentLevel = level;
      this.page = 0;
      this.needLoadMore = true;
      this.books = [];
      this.listkey++;
    },
    infiniteHandler($state) {
      this.page++;
      getPCBookList(this.page, this.limit, this.currentLevel).then((res) => {
        let tempData = res.books;
        this.needLoadMore = tempData.length > 0 ? true : false;
        $state.loaded();
        if (this.needLoadMore == false) {
          $state.complete();
        }
        this.books.push(...tempData);
        this.loading = false;
      });
    },
    searchTextClickHandler() {
      this.dialog = true;
    },
  },
  created() {
    if (this.$route.query.level) {
      this.currentLevel = parseInt(this.$route.query.level);
    }
    // this.getBooks();
  },
  computed: {
    showIcon() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  components: {
    PcFrame,
    PcMoreBooksLevels,
    PcMoreBooksList,
    PcReaderBookSearch,
  },
};
</script>

<style lang="scss" scoped>
.blurred {
  filter: blur(10px);
}

.head {
  max-height: 78px;
  height: 78px;
}

.head-info {
  max-width: 1200px;
  max-height: 78px;
}

.col {
  max-height: 78px;
}

.content {
  max-width: 1200px;
  margin: auto;
}

.item {
  height: 100%;
}

.search-text-bg {
  max-width: 587px;
  width: 100%;
}

.search-text {
  max-width: 587px;
  min-width: 100%;
  height: 50px;
  margin: auto;

  background: #f6f7f9;
  box-shadow: 0px 1px 12px 0px rgba(171, 171, 171, 0.2);
}

::v-deep .v-text-field input {
  padding: 2.5px;
}

.user-info {
  height: 100%;
}

body {
  overflow: hidden;
}
</style>
