<template>
  <v-list class="list">
    <v-list-item
      v-for="level in levels"
      :key="level"
      class="item"
      :class="selectedLevel == level ? 'primary' : 'white'"
      @click="levelItemClickHandler(level)"
    >
      <v-img
        class="mx-auto"
        max-width="120"
        max-height="18"
        :src="levelImg(level)"
      ></v-img>
    </v-list-item>
  </v-list>
</template>
<script>
import { getVisibleLevels } from "@/api/api-pc.js";
export default {
  props: {
    currentLevel: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectedLevel: 0,
      levels: [],
    };
  },
  created() {
    getVisibleLevels().then((res) => {
      this.levels = res.levels;
    });
    this.selectedLevel = this.currentLevel;
  },
  methods: {
    levelItemClickHandler(level) {
      this.selectedLevel = level;
      this.$emit("levelSelected", level);

      this.$track.event({
        category: "page",
        name: "tag_page",
        value: level,
      });
    },
    levelImg(level) {
      if (this.selectedLevel == level) {
        return require("@/assets/level_white/level_white_" + level + ".png");
      } else {
        return require("@/assets/level_gray/level_gray_" + level + ".png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  width: 216px;
}

.item {
  width: 216px;
  height: 60px;
  border-radius: 4px 0px 0px 4px;
}
</style>
